import { LeagueLink } from '@/interfaces/core';

import honduras from '@/public/img/icons/honduras2.png';

export const FAKE_EMAIL = '@hondubet.io';
export const ALGORITHM = 'aes-256-gcm';
export const DEFAULT_NICK = 'player-';
export const COMMAJOINS = ',';
export const LOCAL = 'local';
export const ROWS_PER_PAGE = 10;
export const PAYPHONE_CONVERSION_RATE = 0.040;
export const AUTHENTICATED_SESSION = 'authenticated';
export const FUTBOL_OPTION = {
  title: 'Futbol',
  icon: 'ball',
  type: 'sport',
  list: [
    {
      link: '/deportes?leagueLink=championship/16808',
      name: 'Champions League',
      icon: 'icon-logo_championsleague',
    },
    {
      link: '/deportes?leagueLink=championship/2936',
      name: 'Premier League',
      icon: 'icon-logo_premierleague1',
    },
    {
      link: '/deportes?leagueLink=championship/2941',
      name: 'La Liga',
      icon: 'icon-logo_laliga_sm',
    },
    {
      link: '/deportes?leagueLink=championship/16809',
      name: 'EuroLeague',
      icon: 'icon-UEFA-Europa-League-_1_',
    },
    {
      link: '/deportes?leagueLink=championship/2950',
      name: 'Bundesliga',
      icon: 'icon-logo_bundesliga',
    },
  ],
};
export const MAX_WITHDRAWAL_AMOUNT = 30000;
export const MIN_WITHDRAWAL_AMOUNT = 500;
export const LEAGUES_POPULAR: LeagueLink[] = [
  {
    href: '/deportes?leagueLink=championship/3685',
    alt: 'honduras',
    png: honduras,
  },
  {
    href: '/deportes?leagueLink=championship/2941',
    src: 'icon-logo_laliga',
    alt: 'laliga',
  },
  {
    href: '/deportes?leagueLink=championship/2936',
    src: 'icon-logo_premierleague',
    alt: 'premier',
  },
  {
    href: '/deportes?leagueLink=championship/16808',
    src: 'icon-logo_championsleague1',
    alt: 'champions',
  },
  { href: '/deportes?leagueLink=championship/2980', src: 'icon-logo_nba', alt: 'nba' },
  { href: '/deportes?leagueLink=category/674', src: 'icon-logo_ufc', alt: 'ufc' },
  { href: '/deportes?leagueLink=/category/574', src: 'icon-logo_afa', alt: 'afa' },
  {
    href: '/deportes?leagueLink=championship/2950',
    src: 'icon-logo_bundesliga',
    alt: 'bundesliga',
  },
  {
    href: '/deportes?leagueLink=championship/16809',
    src: 'icon-logo_europa_league',
    alt: 'europa',
  },
  {
    href: '/deportes?leagueLink=championship/2942',
    src: 'icon-logo_serieA',
    alt: 'seriaA',
  },
  {
    href: '/deportes?leagueLink=championship/2943',
    src: 'icon-logo_ligue01',
    alt: 'ligue1',
  },
  { href: '/deportes?leagueLink=championship/3281', src: 'icon-logo_nfl', alt: 'nfl' },
  { href: '/deportes?leagueLink=championship/3232', src: 'icon-logo_nhl', alt: 'nhl' },
];

export const AUTHENTICATED_SESSION_STATUS = 'authenticated';
export const UNAUTHENTICATED_SESSION_STATUS = 'unauthenticated';
export const LOADING_SESSION_STATUS = 'loading';
