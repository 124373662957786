export const messages = {

  changePassword: 'Agrega más seguridad a tu cuenta de Hondubet cuando inicias sesión con correo electrónico / contraseña. Se enviará un código de verificación a tu correo electrónico cada vez que inicies sesión para proteger tu cuenta de forma segura.',
  changesSavedSsuccessfully: 'Cambios guardados con éxito',
  chooseDepositMethod: 'Escoge tu método de depósito favorito:',
  confirmAge: 'Confirmo que tengo 18 años y que he leído los Terminos del Servicio',
  deactivateAccount: '¿Quiere desactivar temporalmente su cuenta?',
  deactivateButton: 'Sí, desactiva mi cuenta',
  emailSecurity: 'Agrega más seguridad a tu cuenta de Hondubet cuando inicias sesión con correo electrónico / contraseña.',
  finalNote: 'Nuestro compromiso es brindar un servicio transparente y eficiente. Agradecemos su comprensión y paciencia durante los periodos de alta demanda. Su seguridad y satisfacción son nuestra máxima prioridad.',
  highDemandPeriods: 'Durante los periodos de mayor actividad, el procesamiento de retiros en efectivo puede experimentar retrasos. Agradecemos su paciencia mientras trabajamos para procesar su solicitud lo más rápido posible.',
  loyaltyBonusDescription: 'Quiero recibir promociones para ganar crédito gratis y conocer las nuevas funciones.',
  manualReview: 'Cada solicitud de retiro es cuidadosamente revisada por nuestro equipo para asegurar su autenticidad y cumplimiento con nuestras políticas de seguridad.',
  recommendation: 'Le recomendamos revisar el historial de transacciones en su cuenta para obtener actualizaciones sobre el estado de su solicitud de retiro. En caso de que su retiro no haya sido procesado dentro del plazo estimado o si tiene alguna pregunta, por favor, no dude en contactar a nuestro equipo de soporte. Estamos aquí para ayudarle.',
  regionAnnouncementsDescription: 'Notifícame cuando las apuestas y otros juegos de Hondubet estén disponibles en mi región.',
  transferFeeNote: '¡Ten en cuenta! El valor del depósito no incluye la tarifa de transferencia. Por favor asegúrese de transferir el monto con la tarifa de transferencia incluida.',
  twoFactorAuthentication: 'Agrega más seguridad a tu cuenta de Hondubet cuando inicias sesión con correo electrónico / contraseña. Se enviará un código de verificación a tu correo electrónico cada vez que inicies sesión para proteger tu cuenta de forma segura.',
  usdtValueChange: 'El valor de USDT:TRC20 puede cambiar entre ahora y el momento en que recibamos tu depósito.',
  verification: 'Las solicitudes de retiro pueden requerir verificaciones adicionales con las instituciones bancarias, lo que podría añadir tiempo al proceso de aprobación.',
  winningBetsDescription: 'Envíame un correo electrónico cuando gane una apuesta o un premio.',
  withdrawalProcessingInfo: 'Los retiros en efectivo son revisados y aprobados manualmente para garantizar la seguridad y precisión de cada operación. Nuestro objetivo es procesar todas las solicitudes de retiro dentro de un día hábil; sin embargo, el tiempo de procesamiento puede variar debido a varios factores:',
  requestSuccess: 'Solicitud procesada correctamente',
};
